'use strict'
import store from "@/store";
import constants from "@/constants";

const generateMixins = {
    data: () => {
        return {
            constants
        }
    },
    // watch: {
    //     '$store.state.user.role'() {
    //         console.log(this.$store.state.user.role)
    //     }
    // },
    methods: {
        isSuperAdmin(role = store.state.user.role) {
            return role === this.constants.userRoles.superAdmin
        },
        isAdmin(role) {
            return role === this.constants.userRoles.admin
        },
        checkUserCanEditCustomer(role) {
            if (!role) return false

            const superAdmin = this.constants.userRoles.superAdmin
            const admin = this.constants.userRoles.admin
            const moderator = this.constants.userRoles.moderator
            const accountant = this.constants.userRoles.accountant
            const customer = this.constants.userRoles.customer
            const employeeWorkshopMaster = this.constants.userRoles.employeeWorkshopMaster
            const employeeMaster = this.constants.userRoles.employeeMaster

            const isSuperAdmin = this.$store.state.user.role === superAdmin
            const isAdmin = this.$store.state.user.role === admin
            // const isModerator = this.$store.state.user.role === moderator
            const isAccountant = this.$store.state.user.role === accountant
            const isCustomer = this.$store.state.user.role === customer

            switch (+role) {
                case superAdmin:
                    return isSuperAdmin
                case admin:
                    return isSuperAdmin || isAdmin
                case moderator:
                    return isSuperAdmin || isAdmin || isAccountant
                case accountant:
                    return isSuperAdmin || isAdmin
                case customer:
                    return !isCustomer
                case employeeWorkshopMaster:
                    return isSuperAdmin || isAdmin || isAccountant
                case employeeMaster:
                    return isSuperAdmin || isAdmin || isAccountant
                default:
                    return false
            }
        },
    },
    computed: {
        // userCanEditClosedFinancialPeriodData() {
        //     return this.$store.state.user.role === this.constants.userRoles.superAdmin ||
        //         this.$store.state.user.role === this.constants.userRoles.admin ||
        //         this.$store.state.user.role === this.constants.userRoles.accountant
        // },
        userIsAdminOrAccountant() {
            const superAdmin = this.constants.userRoles.superAdmin
            const admin = this.constants.userRoles.admin
            const accountant = this.constants.userRoles.accountant

            return !!(this.$store.state.user.role === superAdmin ||
                this.$store.state.user.role === admin ||
                this.$store.state.user.role === accountant)
        },
        userIsAdminOrModerator() {
            if (this.$store.state.user) {
                return !!(this.$store.state.user.role === constants.userRoles.superAdmin ||
                    this.$store.state.user.role === constants.userRoles.admin ||
                    this.$store.state.user.role === constants.userRoles.moderator ||
                    this.$store.state.user.role === constants.userRoles.accountant)
            } else {
                return false
            }
        },
        userCanEditCustomer() {  //Modal with positions CustomerDropdown
            const role = this.selectedCustomer?.role
            // console.log('userCanEditCustomer')
            // const role = this.selectedCustomer ? this.selectedCustomer.role : null
            if (!role) return true

            const superAdmin = this.constants.userRoles.superAdmin
            const admin = this.constants.userRoles.admin
            const moderator = this.constants.userRoles.moderator
            const accountant = this.constants.userRoles.accountant
            const customer = this.constants.userRoles.customer
            const employeeWorkshopMaster = this.constants.userRoles.employeeWorkshopMaster
            const employeeMaster = this.constants.userRoles.employeeMaster

            switch (+role) {
                case superAdmin:
                    return this.$store.state.user.role === superAdmin
                case admin:
                    return this.$store.state.user.role === superAdmin || this.$store.state.user.role === admin
                case moderator:
                    return this.$store.state.user.role === superAdmin || this.$store.state.user.role === admin
                case accountant:
                    return this.$store.state.user.role === superAdmin || this.$store.state.user.role === admin
                case customer:
                    return this.$store.state.user.role !== customer
                case employeeWorkshopMaster:
                    return this.$store.state.user.role === superAdmin ||
                        this.$store.state.user.role === admin ||
                        this.$store.state.user.role === accountant
                case employeeMaster:
                    return this.$store.state.user.role === superAdmin ||
                        this.$store.state.user.role === admin ||
                        this.$store.state.user.role === accountant
                default:
                    return false
            }
        },
    }
}

export default generateMixins
