<!--<template @click="onDocumentClick">-->
<template>
	<div class="topbar p-d-flex p-jc-between p-ai-center" id="appTopbar">
    <div class="p-d-flex p-ai-center">
<!--      <router-link to="/" class="logo-link" v-if="!$store.state.mobileLayout">-->
<!--        <img v-if="!logoIsSmall" class="logo" src="assets/layout/images/logo.png" />-->
<!--        <img v-else class="logo" src="assets/layout/images/logo_small.png" />-->
<!--        <div class="p-d-flex p-jc-between p-ai-center logo-wrapper">-->
<!--          <img class="logo" src="assets/layout/images/logo_small.png" />-->
<!--          <div v-if="!logoIsSmall" class="logo-text">VirtualGarage</div>-->
<!--        </div>-->
<!--        <div class="logo-wrapper">-->
<!--          <img class="logo-img" src="/assets/images/logo.png" alt="">-->
<!--        </div>-->
<!--        <Logo :maxHeight="50"/>-->
<!--      </router-link>-->
<!--      <Logo :maxHeight="50" v-if="!$store.state.mobileLayout"/>-->
      <router-link to="/" class="logo-link" v-if="!$store.state.mobileLayout" draggable="false">
        <Logo :maxHeight="50"/>
      </router-link>
      <button class="p-link menu-button p-jc-between p-ai-center" @click="onMenuButtonClick">
        <i class="pi pi-align-left"></i>
      </button>
    </div>
    <Button v-if="settings.isDevMode" :label="$t('Role') + ': ' + computedRole" class="p-button-rounded" @click="changeRole" />

<!--    <div class="p-d-flex p-jc-between p-ai-center">-->
<!--  dsafsaf-->
<!--    </div>-->
    <div class="p-d-flex p-jc-between p-ai-center">
      <div v-if="userHasActiveEmailAccounts" class="p-overlay-badge p-mr-4" style="font-size: 20px">
        <!--      <Button type="button" icon="ti-email" label="Messages"/>-->
        <router-link :to="{ path: '/messages' }" draggable=false>
          <Button>
            <i class="ti-email icon" style="font-size: 20px"></i>
          </Button>
          <span v-if="$store.state.unansweredEmailsCount && +$store.state.unansweredEmailsCount" class="p-badge p-badge-danger badge">{{ $store.state.unansweredEmailsCount }}</span>
        </router-link>
      </div>

      <div v-if="userIsAdminOrModerator" class="p-overlay-badge p-mr-4" style="font-size: 20px">
        <!--      <Button type="button" icon="ti-email" label="Messages"/>-->
        <router-link :to="{ path: '/offers' }" draggable=false>
          <Button>
            <i class="ti-clipboard icon" style="font-size: 20px"></i>
          </Button>
          <span v-if="$store.state.newOffersCount && +$store.state.newOffersCount" class="p-badge p-badge-danger badge" >{{ $store.state.newOffersCount }}</span>
        </router-link>
      </div>
      <div class="p-mr-4" v-if="!$store.state.mobileLayout">
        <TopbarLanguageSelector
            :languageMenuActive="languageMenuActive"
            :language="language"
            @language-menubutton-click="onLanguageMenuButtonClick">
        </TopbarLanguageSelector>
      </div>

      <div v-if="userIsAdminOrModerator && !$store.state.mobileLayout" style="position:relative;" class="p-mr-4">
        <div class="topbar-search-modified">
          <InputText @input="searchCustomers" type="text" v-model.trim="searchData" :placeholder="$t('Search')" autocomplete="off"/>
<!--          <InputText @keypress="searchCustomers" type="text" v-model.trim="searchData" :placeholder="$t('Search')" autocomplete="off"/>-->
          <span v-if="!searchData?.length" class="pi pi-search"></span>
          <span v-else class="pi pi-times pointer" @click="resetSearch"></span>
          <!--        <Button @click="toggleCustomersOverlay($event)">AAA</Button>-->
        </div>

<!--        <div class="topbar-search-result background-theme-color"-->
        <div class="topbar-search-result"
             v-if="searchData?.length">
          <Spinner v-if="isLoading" :isSmall="true"></Spinner>
<!--          :class="{'datatable-is-loading':isLoading}"-->
          <table v-else-if="customersList?.length"
                 class="p-datatable p-datatable-striped p-datatable-sm p-datatable-hoverable-rows"
                  style="border-collapse: collapse">
            <tbody class="p-datatable-tbody">
              <tr v-for="customer of customersList" :key="customer.id">
                <td class="no-wrap">
  <!--                {{ customer.id }} : -->
<!--                  {{ customer.first_name }} {{ customer.last_name }}-->
                  <div class="p-d-flex p-ai-center">
                    <CustomerStatusHistoryButton :customerStatus="customer.customerStatus" :isChangeable="false"/>
                    <div v-if="customer.type === constants.userTypes.legal_entity && customer.company_name" class="p-mr-1">{{ customer.company_name }} </div>
                    <div v-else class="p-mr-1">{{ customer.first_name }} {{ customer.last_name }} </div>
                    <div class="customer-data-flag-wrapper">
                      <span :class="'flag flag-' + customer.flag_code"></span>
                    </div>
                  </div>
                </td>
                <td class="no-wrap">
                  <span>
                    <span class="p-mr-2" v-if="customer.type === constants.userTypes.legal_entity"><span v-if="customer.company_phone_code">+{{ customer.company_phone_code}}</span> {{ customer.company_phone_number  }}</span>
                    <span class="p-mr-2" v-else><span v-if="customer.phone_code">+{{ customer.phone_code }}</span> {{ customer.phone_number }}</span>
                  </span>
  <!--                <span class="p-mr-2">-->
  <!--                  <span class="ti-hummer"></span>-->
  <!--                  <span class="p-text-bold p-ml-1">{{ customer.orders_count ?? 0 }}</span>-->
  <!--                </span>-->
  <!--                <span class="p-mr-2">-->
  <!--                  <span class="ti-clipboard"></span>-->
  <!--                  <span class="p-text-bold p-ml-1">{{ customer.offers_count ?? 0 }}</span>-->
  <!--                </span>-->
  <!--                <span>-->
  <!--                  <span class="ti-car"></span>-->
  <!--                  <span class="p-text-bold p-ml-1">{{ customer.cars_count ?? 0 }}</span>-->
  <!--                </span>-->
                </td>

                <td @click.prevent="openCustomerInNewWindow(customer.id)">
  <!--                <router-link :to="{ path: `/customers/${customer.id}` }" target="_blank" class="p-d-flex p-ai-center p-flex-wrap">-->
  <!--                  <i style="font-size: 0.85rem; color: inherit" class="pi pi-external-link"></i>-->
  <!--                  <i style="color: inherit" class="pi pi-eye"></i>-->
                  <Button class="p-button-secondary p-button-sm" icon="pi pi-eye"></Button>
  <!--                </router-link>-->
                </td>
              </tr>
            </tbody>
          </table>
          <div v-else class="text-color" style="padding: 22px 0; text-align: center">No data</div>
        </div>
      </div>

<!--      <Button :label="$t('Search')" icon="pi pi-search"></Button>-->
<!--      <Button style="font-size: 20px" @click="toggleCustomersOverlay($event)" class="p-mr-4">-->
<!--        <i class="pi pi-search" style="font-size: 1.1rem"/>-->
<!--      </Button>-->
<!--      <OverlayPanel ref="op" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '350px'}">-->
<!--        <div class="p-input-icon-right" style="width: 100%;">-->
<!--          <InputText type="text" style="width: 100%;" v-model.trim="searchData" :placeholder="$t('Search') + '...'" autocomplete="off"/>-->
<!--          <i class="pi pi-search" />-->
<!--        </div>-->
<!--        <div>-->
<!--          <div v-if="!customersList?.length">No data</div>-->
<!--          <div v-else>-->
<!--            Customers on-->
<!--          </div>-->
<!--        </div>-->
<!--      </OverlayPanel>-->


<!--      <div class="topbar-search-modified p-mr-4">-->
<!--        <CustomDropdown class="dropdown__select"-->
<!--                  :searchData="searchData"-->
<!--                  v-model="selectedCustomer"-->
<!--                  :options="customersList"-->
<!--                  filterPlaceholder="Find customer"-->
<!--                  :filterFields="['plate_number', 'first_name', 'last_name', 'company_name', 'reg_number']"-->
<!--                  :filter="true"-->
<!--                  @filter="searchCustomers($event)"-->
<!--                  :createButton="false"-->
<!--                  placeholder="Search..."-->
<!--                  :showClear="false">-->
<!--          <template #value="slotProps">-->
<!--            <div v-if="slotProps.value">-->
<!--              Search...-->
<!--            </div>-->
<!--            <span v-else>Search...</span>-->
<!--          </template>-->
<!--          <template #option="slotProps">-->
<!--            <div>{{ slotProps.option.first_name }} {{ slotProps.option.last_name }}</div>-->
<!--&lt;!&ndash;            <div>{{ // slotProps.option.company_name }} <span v-if="slotProps.option.name">({{ slotProps.option.name }})</span></div>&ndash;&gt;-->
<!--          </template>-->
<!--        </CustomDropdown>-->
<!--      </div>-->

<!--      <div>-->
<!--        <div style="position: relative;">-->
<!--          fsasafas-->
<!--        </div>-->
<!--        <div style="position: absolute; background-color: green">-->
<!--          <div v-if="!customersList?.length">No data</div>-->
<!--          <div v-else>-->
<!--            <div v-for="item of customersList" :key="item.id">-->
<!--              {{ item }}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->


<!--      <button v-if="$store.state.mobileLayout" @click="toggleSearchInput" class="p-link search-button">-->
<!--        <span v-if="!showSearchInput" class="pi pi-search search-button__icon"></span>-->
<!--        <span v-else class="pi pi-times search-button__icon"></span>-->
<!--      </button>-->

<!--      <div v-if="showSearchInput && $store.state.mobileLayout">-->
<!--        <div class="topbar-search-modified&#45;&#45;dialog">-->
<!--          <InputText placeholder="Search"/>-->
<!--          <span class="pi pi-search"></span>-->
<!--        </div>-->
<!--      </div>-->

      <Button v-if="$store.state.user" class="p-link profile profile-button p-text-right" @click="onTopbarMenuButtonClick">
        <div v-if="!$store.state.mobileLayout" class="p-mr-2">
<!--          <div class="username" style="font-size: 0.9em">{{ $store.state.user.first_name }} {{ $store.state.user.last_name }}-->
          <div style="font-size: 0.9em">{{ $store.state.user.first_name }} {{ $store.state.user.last_name }}
<!--            <div class="profile-button__role">CARVEX OÜ</div>-->
            <div class="profile-button__role">{{ computedRole }}</div>
          </div>
        </div>

        <div v-if="$store.state.user" class="avatar-wrapper">
<!--          <img v-if="$store.state.user?.profile_img" :src="settings.apiEndPoint + settings.pathToAvatarsDir + master.user.profile_img + '.jpg'" :class="{'p-mr-1': item.masters.length > 1 && i !== item.masters.length - 1}" :alt="master.user.first_name + ' ' + master.user.last_name" :title="master.user.first_name + ' ' + master.user.last_name">-->
<!--          <img v-if="$store.state.user?.profile_img" :src="settings.apiEndPoint + settings.pathToAvatarsDir + $store.state.user?.profile_img" :class="{'p-mr-1': item.masters.length > 1 && i !== item.masters.length - 1}" :alt="master.user.first_name + ' ' + master.user.last_name" :title="master.user.first_name + ' ' + master.user.last_name">-->
          <img v-if="$store.state.user?.profile_img" :src="settings.apiEndPoint + settings.pathToAvatarsDir + $store.state.user?.profile_img">
          <img v-else :src="settings.apiEndPoint + settings.pathToAvatarsDir + 'avatar_placeholder.png'">
        </div>

<!--        <img class="profile-button__avatar" src="/assets/layout/images/avatar/avatar1.png" alt="topbar-avatar" />-->
<!--        <i class="pi pi-angle-down profile__arrow"></i>-->
      </Button>
    </div>

      <ul :class="topbarItemsClass" role="menu">

<!--        <li :class="[{'menuitem-active': activeTopbarItem === 'profile'}]"-->
<!--          @click="$emit('topbar-item-click', {originalEvent:$event,item:'profile'})" role="none">-->
<!--        <li>-->
<!--          <router-link to="/">-->
<!--            <button class="p-link">-->
<!--              <i class="topbar-icon pi pi-fw pi-user"></i>-->
<!--              <span class="topbar-item-name">Profile</span>-->
<!--            </button>-->
<!--          </router-link>-->
<!--        </li>-->
        <li>
          <button class="p-link" @click="logoutOnClick">
            <i class="topbar-icon pi pi-fw pi-power-off"></i>
            <span class="topbar-item-name">Logout</span>
          </button>
        </li>

<!--        <li :class="[{'menuitem-active': activeTopbarItem === 'settings'}]">-->
<!--          <button class="p-link" @click="$emit('topbar-item-click', {originalEvent:$event,item:'settings'})">-->
<!--            <i class="topbar-icon pi pi-fw pi-cog"></i>-->
<!--            <span class="topbar-item-name">Settings</span>-->
<!--          </button>-->
<!--        </li>-->


<!--        <li :class="[{'menuitem-active': activeTopbarItem === 'notifications'}]">-->
<!--          <button class="p-link" @click="$emit('topbar-item-click', {originalEvent:$event,item:'notifications'})">-->
<!--            <i class="topbar-icon pi pi-fw pi-bell"></i>-->
<!--            <span class="topbar-item-name">Notifications</span>-->
<!--            <span class="topbar-badge">2</span>-->
<!--          </button>-->
<!--        </li>-->

<!--        <li :class="[{'menuitem-active': activeTopbarItem === 'logout'}]">-->
<!--          <button class="p-link p-ripple" @click="$emit('topbar-item-click', {originalEvent:$event,item:'logout'})">-->
<!--            <i class="topbar-icon pi pi-fw pi-power-off"></i>-->
<!--            <span class="topbar-item-name">Logout</span>-->
<!--          </button>-->
<!--        </li>-->
      </ul>
	</div>
</template>

<script>
import TopbarLanguageSelector from '@/components/TopbarLanguageSelector'
// import authService from '@/services/auth.service'
import EventBus from '@/event-bus'
// import roles from '@/translations/roles'
import constants from '@/constants'
import roles from "@/translations/roles";
// import httpMixins from "@/mixins/httpMixins";
import httpClient from "@/services/http.services";
import settings from "@/settings";
import CustomerStatusHistoryButton from "@/pages/users/components/CustomerStatusHistoryButton";
import webSocketMixins from "@/mixins/webSocketMixins";
import Logo from "@/components/Logo";
import showErrorsMixins from "@/mixins/showErrorsMixins";
import permissionsMixins from "@/mixins/permissionsMixins";

export default {
  mixins: [ showErrorsMixins, webSocketMixins, permissionsMixins ],
  components: { TopbarLanguageSelector, CustomerStatusHistoryButton, Logo },
  emits: ['menubutton-click', 'topbar-menubutton-click', 'language-menubutton-click', 'toggle-search-input', 'logout'],
	props: {
		topbarMenuActive: Boolean,
    language: String,
		languageMenuActive: Boolean,
		activeTopbarItem: String,
    // user: Object,
    logoIsSmall: Boolean,
    showSearchInput: Boolean,
	},
  data() {
    return {
      getCountInterval: null,
      // settings,
      selectedCustomer: null,
      customersList: [],
      searchData: null,
      // blockCustomersSearching: false,
      isLoading: false,
      i: 1,
      constants,
      settings
    }
  },
  mounted() {
    // if (this.userHasActiveEmailAccounts) {
    this.setGetNewEmailsAndOffersCountInterval()
    // }

    // if(this.$route.path !== '/offers' && this.userIsAdminOrModerator) {
    //   this.getNewOffersCount()
    // }
    this.getNewEmailsAndOffersCount()
  },
  unmounted() {
    this.customersList = []
    this.clearGetNewEmailsAndOffersCountInterval()
  },
  watch: {
    '$store.state.user.role'() {
      this.getNewEmailsAndOffersCount()
    },
    // '$store.state.user.emailAccounts'() {
    //   if (this.userHasActiveEmailAccounts) this.setGetNewEmailsAndOffersCountInterval()
    // },
    // searchData() {
    //   if () {
    //     this.searchCustomers()
    //   }
    // },
    '$route'() {
      this.resetSearch()
    }
  },
  methods: {
    setGetNewEmailsAndOffersCountInterval() {
      // if (!this.userHasActiveEmailAccounts || this.getCountInterval) return
      if (this.getCountInterval) return
      // console.log('setGetNewEmailsAndOffersCountInterval')
      const oneMinuteInMilliseconds = 60 * 1000
      // const oneMinuteInMilliseconds = 1000
      this.getCountInterval = setInterval(() => {
        // if (this.$route.path !== '/messages') {
        if (this.userIsAdminOrModerator) {
          this.getNewEmailsAndOffersCount()
        }
        // }
      }, oneMinuteInMilliseconds)
    },
    clearGetNewEmailsAndOffersCountInterval() {
      if (!this.getCountInterval) return false
      clearInterval(this.getCountInterval)
    },
    async getNewEmailsAndOffersCount() {
      if (!this.userIsAdminOrModerator) return false;
      try {
        const { status, data } = await httpClient('common/get-new-emails-and-offers-count')
        if (status === 200 && data) {
          // if (data.offers) {
          this.$store.commit('updateNewOffersCount', data.offers ?? 0)
          // }
          // console.log(status, data)
          // if (data.emails) {
          if (this.userHasActiveEmailAccounts) {
            this.$store.commit('updateUnansweredEmailsCount', data.emails ?? 0)
          }
          // }
        }
      } catch(err) {
        this.showError(err)
      }
    },
    openCustomerInNewWindow(customerId) {
      const routeUrl = this.$router.resolve({
        path: `/customers/${customerId}`
      })
      window.open(routeUrl .href, '_blank');

      // this.$router.push({
      //   path: `/customers/${customerId}`,
      // })
    },
    resetSearch() {
      // this.blockCustomersSearching = true
      this.searchData = null
      this.customersList = []
    },
    toggleCustomersOverlay(event) {
      this.$refs.op.toggle(event);
    },
    // // showCustomersOverlay(event) {
    // //   this.$refs.op.show(event);
    // // },
    // // hideCustomersOverlay(event) {
    // //   this.$refs.op.hide(event);
    // // },
    async searchCustomers() {
      // if (!this.blockCustomersSearching) return false
      const value = this.searchData
      this.isLoading = true
      // const value = event.value

      // if (value?.length <= 2) {
      //   // this.customers = []
      //   if (value.length === 0 && this.selectedCustomer) {
      //     this.customers = [{...this.selectedCustomer}]
      //   } else {
      //     this.customers = null
      //   }
      //   return false
      // }
      // if (!value?.length) {
      //   this.hideCustomersOverlay()
      // } else {
      //   this.showCustomersOverlay()
      // }

      try {
        const { status, data } = await httpClient('user/search-customers-with-cars?expand=customerStatus&search=' + value)
        if (status === 200 && data?.length) {
          this.customersList = data
        } else {
          this.customersList = []
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.isLoading = false
      }
      // console.log(this.customersList)

      // finally {
      //   this.customersDataIsLoading = false
      //   this.showCustomerMinCharsMessage = false
      // }
    },
    changeRole() {
      let role;
      if (this.$store.state.user.role === constants.userRoles.superAdmin) {
        role = 3
      } else if (this.$store.state.user.role === constants.userRoles.admin) {
        role = 5
      } else if (this.$store.state.user.role === constants.userRoles.moderator) {
        role = 7
      } else if (this.$store.state.user.role === constants.userRoles.accountant) {
        role = 9
      } else if (this.$store.state.user.role === constants.userRoles.customer) {
        role = 1
      }
      this.$store.commit('changeUserRole', role)
    },
    logoutOnClick() {
      // console.log(this.$store.state.webSocket?.readyState)
      this.sendLogoutWebSocket(this.$store.state.user.id)
      this.changeFullTheme('blue-light')
      // authService.logout()
      // this.logout()
      this.$emit('logout')
    },
    // logout() {
    //   this.changeFullTheme('blue-light')
    //   authService.logout()
    // },
    toggleSearchInput() {
      this.$emit('toggle-search-input')
    },
    onMenuButtonClick(event) {
        this.$emit('menubutton-click', event);
    },
    onTopbarMenuButtonClick(event) {
      this.$emit('topbar-menubutton-click', event);
    },
    onLanguageMenuButtonClick(event) {
      this.$emit('language-menubutton-click', event);
    },
    changeFullTheme(theme) {
      this.theme = theme.split('-')[0];
      this.shema = theme.split('-')[1];
      this.changeStyleSheetUrl('layout-css', theme, 'layout');
      this.changeStyleSheetUrl('theme-css', theme, 'theme');
    },
    changeStyleSheetUrl(id, value, prefix) {
      let element = document.getElementById(id);
      let urlTokens = element.getAttribute('href').split('/');
      urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
      let newURL = urlTokens.join('/');
      this.replaceLink(element, newURL);

      if (value.indexOf('dark') !== -1)
        this.schema = 'dark';
      else if(value.indexOf('dim') !== -1)
        this.schema = 'dim';
      else
        this.schema = 'light';

      EventBus.emit('on-schema', this.schema);
    },
    replaceLink(linkElement, href) {
      const id = linkElement.getAttribute('id');
      const cloneLinkElement = linkElement.cloneNode(true);

      cloneLinkElement.setAttribute('href', href);
      cloneLinkElement.setAttribute('id', id + '-clone');

      linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

      cloneLinkElement.addEventListener('load', () => {
        linkElement.remove();
        cloneLinkElement.setAttribute('id', id);
      });
    },
  },
	computed: {
    userHasActiveEmailAccounts() {
      const accountsWithShowMessages =  this.$store.state.user.emailAccounts?.find(account => account.show_messages)
      // return !!accountsWithShowMessages?.length
      return !!accountsWithShowMessages
    },
    computedRole() {
      if (this.$store.state.user.role) {
        return roles.find(role => role.num === +this.$store.state.user.role)?.name[this.$i18n.locale]
      } else {
        return null
      }
    },
		topbarItemsClass() {
			return ['topbar-menu fadeInDown', {
				'topbar-menu-visible': this.topbarMenuActive
			}];
		}
	}
}
</script>
<style scoped lang="scss">
//.topbar-search-modified {
//  @media screen and (max-width: 640px) {
//    display: none;
//  }
//}

.topbar-search-result {
  position: absolute;
  right: 0;
  top: 44px;
  //padding: 3px;
  min-width: 175px!important;
  //width: 100%;
}

.search-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 6px;
  padding: 10px;
  color: inherit !important;

  .search-button__icon {
    margin: 0 5px;
    font-size: 20px;
  }
}

.profile-button {
  display: flex;
  justify-content: center;
  align-items: center;

  .profile-button__role {
    text-align: right;
    font-size: 0.9em;
    font-weight: 400;
  }

  .profile-button__avatar {
    margin-left: 28px!important;
  }
}
.logo-link {
  //display: inline-block;
  .logo-wrapper {
    margin-right: 10px;
    &:hover {
      opacity: 0.8;
    }
    .logo-img {
      display: block;
      max-height: 50px!important;
      max-width: 280px!important;
      width: 100%;
      height: 100%;
    }
  }
}
.logo-text {
  color: white;
  font-weight: 500;
  font-size: 28px;
}

.badge {
  //position: relative!important;
  //top: -6px !important;
  //right: 22px!important;
  top: 8px !important;
  right: 4px !important;
}

.avatar-wrapper {
  //display: flex;
  //align-items: center;
  //flex-wrap: wrap;
  //max-height: 45px;
  //max-width: 45px;
  img {
    //display: block;
    height: 42px!important;
    width: 42px!important;
    border-radius: 4px;
    //&:hover {
    //  opacity: 0.8;
    //}
  }
}
</style>
