const en = {
  //Login page
  Welcome: 'Welcome',
  'Sign in to start your session': 'Sign in to start your session',
  Password: 'Password',
  'Repeat password': 'Repeat password',
  'Passwords do not match': 'Passwords do not match',
  'Sign In': 'Sign In',
  'Forgot Password?': 'Forgot Password?',
  'New on our platform?': 'New on our platform?',
  'Create an account': 'Create an account',
  'Incorrect login or password': 'Incorrect login or password',

  //Price request form
  'Minimum length': 'Minimum length',
  'Maximum length': 'Maximum length',
  'Minimum value': 'Minimum value',
  'Maximum value': 'Maximum value',
  'Price request form': 'Price request form',
  'Price request': 'Price request',
  'Vehicle data': 'Vehicle data',
  'Your data': 'Customer data',
  'Desired works / parts': 'Desired works / parts',
  'Description': 'Description',
  'Characters left': 'Characters left',
  'Workshop parts': 'Workshop parts',
  'Customer parts': 'Customer parts',
  'privacyPolicyCheckboxText': 'Сheck the box if you have read and agree with the',
  'privacyPolicyCheckboxLink': 'privacy policy',
  'Fields are required': 'Fields are required',
  'Send request': 'Send request',
  'priceRequestSentThankYouText': 'Thank you! Your request was sent successfully.',
  'priceRequestSentWeReceivedText': 'We will prepare a price offer for you as soon as possible.',
  'Go back': 'Go back',
  'Optional field': 'Optional field',

  //Dashboard
  Dashboard: 'Dashboard',

  //User
  User: 'User',
  Users: 'Users',
  'General information': 'General information',

  //Customers
  Customers: 'Customers',
  Customer: 'Customer',
  'Client card': 'Client card',
  'Credit limit': 'Credit limit',
  Individual: 'Individual',
  Individuals: 'Individuals',
  'Private persons': 'Private persons',
  Companies: 'Companies',
  Company: 'Company',
  'Company name': 'Company name',
  'Adding a new customer': 'Adding a new customer',
  'Customer editing': 'Customer editing',
  //'Adding a new company': 'Adding a new company',
  //'Company editing': 'Company editing',
  'Save password': 'Save password',
  'Adding a new address': 'Adding a new address',
  'Address editing': 'Address editing',
  'Days to pay': 'Days to pay',
  'Services discount': 'Services discount',
  'Products discount': 'Products discount',

  //Employees
  Employee: 'Employee',
  Employees: 'Employees',
  'Employees archive': 'Employees',
  Position: 'Position',
  'Adding a new employee': 'Adding a new employee',
  'Employee editing': 'Employee editing',
  'Personal code': 'Personal code',
  Contract: 'Contract',
  Start: 'Start',
  End: 'End',
  'Salary settings': 'Salary settings',
  Payments: 'Payments',
  Time: 'Time',

  //Orders
  Order: 'Order',
  Orders: 'Orders',
  Qty: 'Qty',
  'Purchase price': 'Purchase price',
  'Selling price': 'Selling price',
  Buy: 'Buy',
  Sell: 'Sell',
  Discount: 'Discount',
  Returned: 'Returned',
  Reason: 'Reason',
  Type: 'Type',
  Cancelled: 'Cancelled',
  Dublicate: 'Дублировать',
  'Create offer': 'Create offer',
  Restore: 'Restore',
  'To archive': 'To archive',
  'Return from archive': 'Return from archive',
  'Increase quantity': 'Increase quantity',
  'Decrease quantity': 'Decrease quantity',
  Paid: 'Fully paid',

  //Orders archive
  'Orders archive': 'Orders archive',

  //Offers
  Offer: 'Offer',
  Offers: 'Offers',

  //Customer offer registration
  'Not found': 'Not found',
  'PIN': 'PIN',
  'Enter pin': 'Enter PIN',
  'PIN is wrong': 'PIN-code is wrong',
  'Login title': 'Please enter PIN to view offer',
  'Login subtitle': 'You can find it with email or sms which you received with this link.',
  'Car data': 'Car data',
  'Number': 'Number',
  'Status': 'Status',
  'Valid until': 'Valid until',
  'Booking time': 'Booking time',
  'Additional information': 'Additional information',
  'Choose booking time': 'Choose appointment time',
  'Price': 'Price',
  'Sum': 'Sum',
  'Sum without tax': 'Sum without VAT',
  'Manual tax input': 'Manual tax input',
  'Total sum': 'Total sum',
  'Appointment booking': 'Appointment booking',
  'Day': 'Day',
  'Working hours': 'Service working hours',
  'Please select time': 'Please select time',
  'Approximate service time': 'Approximate service time',
  'Approximate order end time': 'Approximate order ent time',
  'workTakesMoreTimeText': 'Work takes more time than is left before the end of the working day. Order processing will continue on the next business day.',
  'Additional wishes (optional)': 'Additional wishes (optional)',
  'bookingAgreementWithTermsText': 'I agree that the terms of the order are approximate and depend on the volume of the ordered work. Additional work may be required during order fulfillment.',
  'orderCreatedThankYouText': 'Thank you! Order created successfully.',

  //Warehouse
  'Warehouse': 'Warehouse',
  'Warehouses': 'Warehouses',
  'Warehouse items': 'Warehouse items',
  'Warehouse requests': 'Warehouse requests',

  //Links
  Links: 'Links',
  'Links settings': 'Links settings',

  //Messages
  'Messages': 'Messages',

  //Cars
  Car: 'Car',
  Cars: 'Cars',
  'Adding a new car': 'Adding a new car',
  'Car editing': 'Car editing',
  'Owner': 'Owner',
  'Make': 'Make',
  'Model': 'Model',
  'Registration number': 'Registration number',
  'Plate number': 'Plate number',
  'VIN number': 'VIN number',
  'Engine code': 'Engine code',
  'Production year': 'Production year',
  'Year': 'Year',
  'Drivetrain': 'Drivetrain',
  'Fuel': 'Fuel',
  'Transmission': 'Transmission',
  'Power': 'Power',
  'kW': 'kW',
  'hp': 'hp',
  'Goods': 'Goods',
  'Mileage': 'Mileage',

  //Suppliers
  Supplier: 'Supplier',
  Suppliers: 'Suppliers',
  'Adding a new supplier': 'Adding a new supplier',
  'Supplier editing': 'Supplier editing',
  'Supplier name': 'Supplier name',
  'Bank name': 'Bank name',
  'Bank account': 'Bank account',

  //Taxes
  Taxes: 'Taxes',

  //Cashboxes
  Cashbox: 'Cashbox',
  Cashboxes: 'Cashboxes',

  //Sales invoices
  'Sales invoices': 'Sales invoices',
  'Sales invoice': 'Sales invoice',

  //Purchase invoices
  'Purchase invoices': 'Purchase invoices',
  'Invoice number already exists': 'Invoice number already exists',
  Requests: 'Requests',
  Returns: 'Returns',

  //Cashbox transactions
  'Cashbox transactions': 'Cashbox transactions',
  'All cashboxes': 'All cashboxes',

  //Bank payments
  'Bank payments': 'Bank payments',

  //Card payments
  'Card payments': 'Card payments',

  //Working areas
  'Working areas': 'Working areas',
  'Working area': 'Working area',
  'Adding a new working area': 'Adding a new working area',
  'Working area editing': 'Working area editing',

  //Services
  Services: 'Services',
  'Adding a new service': 'Adding a new service',
  'Service editing': 'Service editing',
  'Adding a new service category': 'Adding a new service category',
  'Service category editing': 'Service category editing',
  Category: 'Category',

  //Settings
  Settings: 'Settings',

  //Email settings
  'Email settings': 'Email settings',
  'Email accounts': 'Email accounts',
  Emails: 'Emails',

  //App Config Component:
  'Input Style': 'Input Style',
  Outlined: 'Outlined',
  Filled: 'Filled',
  'Ripple Effect': 'Ripple Effect',
  'Menu Type': 'Menu Type',
  Static: 'Static',
  Overlay: 'Overlay',
  Horizontal: 'Horizontal',
  Slim: 'Slim',
  'Color schema': 'Color schema',
  Light: 'Light',
  Dark: 'Dark',
  Dim: 'Dim',
  Themes: 'Themes',

  //General, common
  Percent: 'Percent',
  'Login': 'Login',
  'Mo': 'Mo',
  'Tu': 'Tu',
  'We': 'We',
  'Th': 'Th',
  'Fr': 'Fr',
  'Sa': 'Sa',
  'Su': 'Su',
  Date: 'Date',
  'Today': 'Today',
  'Yesterday': 'Yesterday',
  'Tomorrow': 'Tomorrow',
  'Current week': 'Current week',
  'Previous week': 'Previous week',
  'Current month': 'Current month',
  'Previous month': 'Previous month',
  'Custom period': 'Custom period',
  'Required field': 'Required field',
  'Value too large': 'Value too large',
  'Value too small': 'Value too small',
  'Invalid date format': 'Invalid date format',
  Name: 'Name',
  Code: 'Code',
  'First name': 'First name',
  'Last name': 'Last name',
  'Full name': 'Full name',
  Email: 'Email',
  Phone: 'Phone',
  Role: 'Role',
  Country: 'Country',
  'Select': 'Select',
  'Please select': 'Please select',
  Address: 'Address',
  City: 'City',
  State: 'State',
  Postcode: 'Postcode',
  Language: 'Language',
  'Select language': 'Select language',
  Birthdate: 'Birthdate',
  Comment: 'Comment',
  'Reg. number': 'Reg. number',
  'VAT number': 'VAT number',
  Tax: 'Tax',
  Balance: 'Balance',
  Profit: 'Profit',
  Created: 'Created',
  Add: 'Add',
  'Add category': 'Add category',
  'All categories': 'All categories',
  Delete: 'Delete',
  Search: 'Search',
  Page: 'Page',
  Error: 'Error',
  'Unknown error': 'Unknown error',
  Counters: 'Counters',
  'Total records': 'Total records',
  'User archive': 'User archieve',
  'All Rights Reserved' : 'All Rights Reserved',
  'No data available': 'No data available',
  Edit: 'Edit',
  Save: 'Save',
  Cancel: 'Cancel',
  Clear: 'Clear',
  'Data saved': 'Data saved',
  'Data updated': 'Data updated',
  'Data deleted': 'Data deleted',
  Successful: 'Successful',
  Confirm: 'Confirm',
  Close: 'Close',
  'Are you sure you want to delete': 'Are you sure you want to delete',
  'Are you sure you want to delete selected item?': 'Are you sure you want to delete selected item?',
  'Are you sure you want to delete selected items?': 'Are you sure you want to delete selected items?',
  'Are you sure you want to proceed?': 'Are you sure you want to proceed?',
  Yes: 'Yes',
  No: 'No',
  Deleted: 'Deleted',
  Restored: 'Restored',
  h: 'h',  //Hour
  hours: 'hours',
  km: 'km',
  'Change history': 'Change history',
  'No results found': 'No results found',
  'No available options': 'No available options',
  'Value is not a valid email address': 'Value is not a valid email address',
  Undefined: 'Undefined',
  'Phone number has already been taken': 'Phone number has already been taken',
  'Registration number has already been taken': 'Registration number has already been taken',
  'Username has already been taken': 'Username has already been taken',
  'This page is under construction': 'This page is under construction',
  Maintenance: 'Maintenance',
  Size: 'Size',
  Continue: 'Continue',
  'End session': 'End session',
  'Create new': 'Create new',
  Adjustment: 'Adjustment',
  Debit: 'Debit',
  Credit: 'Credit',
}

export default en
