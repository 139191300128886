const ru = {
  //Login page
  Welcome: 'Добро пожаловать',
  'Sign in to start your session': 'Войдите, чтобы начать сеанс',
  Password: 'Пароль',
  'Repeat password': 'Повторите пароль',
  'Passwords do not match': 'Пароли не совпадают',
  'Sign In': 'Войти',
  'Forgot Password?': 'Забыли пароль?',
  'New on our platform?': 'Впервые на нашей платформе?',
  'Create an account': 'Создать аккаунт',
  'Incorrect login or password': 'Неверный логин или пароль',

  //Price request form
  'Minimum length': 'Минимальная длина',
  'Maximum length': 'Максимальная длина',
  'Minimum value': 'Минимальное значение',
  'Maximum value': 'Максимальное значение',
  'Price request form': 'Форма запроса цены',
  'Price request': 'Запрос цены',
  'Vehicle data': 'Данные автомобиля',
  'Your data': 'Ваши данные',
  'Desired works / parts': 'Желаемые работы / запчасти',
  'Description': 'Описание',
  'Characters left': 'Осталось символов',
  'Workshop parts': 'Запчасти мастерской',
  'Customer parts': 'Запчасти клиента',
  'privacyPolicyCheckboxText': 'Поставьте галочку, что вы ознакомлены и согласны c',
  'privacyPolicyCheckboxLink': 'политикой конфиденциальности',
  'Fields are required': 'Поля обязательны для заполнения',
  'Send request': 'Отправить запрос',
  'priceRequestSentThankYouText': 'Спасибо! Ваш запрос успешно отправлен.',
  'priceRequestSentWeReceivedText': 'Мы подготовим для вас ценовое предложение в ближайшее время.',
  'Go back': 'Вернуться назад',
  'Optional field': 'Необязательное поле',

  //Dashboard
  Dashboard: 'Приборная панель',

  //User
  User: 'Пользователь',
  Users: 'Пользователи',
  'General information': 'Общая информация',

  //Customers
  Customers: 'Клиенты',
  Customer: 'Клиент',
  'Client card': 'Карта клиента',
  'Credit limit': 'Кредитный лимит',
  Individual: 'Частное лицо',
  Individuals: 'Частные лица',
  'Private persons': 'Частные лица',
  Companies: 'Компании',
  Company: 'Компания',
  'Company name': 'Название компании',
  'Adding a new customer': 'Добавление нового клиента',
  'Customer editing': 'Редактирование клиента',
  //'Adding a new company': 'Добавление новой компании',
  //'Company editing': 'Редактирование компании',
  'Save password': 'Сохранить пароль',
  'Adding a new address': 'Добавление нового адреса',
  'Address editing': 'Редактирование адреса',
  'Days to pay': 'Дней на оплату',
  'Services discount': 'Скидка на услуги',
  'Products discount': 'Скидка на товары',

  //Employees
  Employee: 'Сотрудник',
  Employees: 'Сотрудники',
  'Employees archive': 'Архив сотрудников',
  Position: 'Должность',
  'Adding a new employee': 'Добавление нового сотрудника',
  'Employee editing': 'Редактирование сотрудника',
  'Personal code': 'Личный код',
  Contract: 'Договор',
  Start: 'Начало',
  End: 'Конец',
  'Salary settings': 'Настройки заработной платы',
  Payments: 'Платежи',
  Time: 'Время',

  //Orders
  Order: 'Заказ',
  Orders: 'Заказы',
  Qty: 'Кол-во',
  'Purchase price': 'Цена покупки',
  'Selling price': 'Цена продажи',
  Buy: 'Покупка',
  Sell: 'Продажа',
  Discount: 'Скидка',
  Returned: 'Возвращено',
  Reason: 'Причина',
  Type: 'Тип',
  Cancelled: 'Отменено',
  Dublicate: 'Дублировать',
  'Create offer': 'Создать предложение',
  Restore: 'Восстановить',
  'To archive': 'Архивировать',
  'Return from archive': 'Вернуть из архива',
  'Increase quantity': 'Увеличить количество',
  'Decrease quantity': 'Уменьшить количество',
  Paid: 'Оплачено',

  //Orders archive
  'Orders archive': 'Архив заказов',

  //Offers
  Offer: 'Ценовое предложение',
  Offers: 'Ценовые предложения',

  //Customer offer registration
  'Not found': 'Не найдено',
  'PIN': 'ПИН',
  'Enter pin': 'Введите ПИН',
  'PIN is wrong': 'Неверный PIN-код',
  'Login title': 'Для просмотра предложения введите PIN-код',
  'Login subtitle': 'Вы можете найти его в письме или смс, которые получили вместе с данной ссылкой.',
  'Car data': 'Данные автомобиля',
  'Number': 'Номер',
  'Status': 'Статус',
  'Valid until': 'Действительно до',
  'Booking time': 'Время бронирования',
  'Additional information': 'Дополнительная информация',
  'Choose booking time': 'Выберите время бронирования',
  'Price': 'Цена',
  'Sum': 'Сумма',
  'Sum without tax': 'Сумма без налога',
  'Manual tax input': 'Ручной ввод налога',
  'Total sum': 'Общая сумма',
  'Appointment booking': 'Бронирование времени',
  'Day': 'День',
  'Working hours': 'Рабочее время',
  'Please select time': 'Пожалуйста, выберите подходящее время',
  'Approximate service time': 'Приблизительное время работ',
  'Approximate order end time': 'Приблизительное время окончания заказа',
  'workTakesMoreTimeText': 'Работы занимают больше времени, чем остаеться до конца рабочего дня. Выполнение заказа будет продолжено на следующий рабочий день.',
  'Additional wishes (optional)': 'Дополнительные пожелания (необязательно)',
  'bookingAgreementWithTermsText': 'Я согласен с тем, что сроки выполнения заказа приблизительные и зависят от объема заказанных работ. В процессе выполнения заказа может потребоваться дополнительная работа.',
  'orderCreatedThankYouText': 'Спасибо! Заказ успешно создан.',

  //Warehouse
  'Warehouse': 'Склад',
  'Warehouses': 'Склады',
  'Warehouse items': 'Складские товары',
  'Warehouse requests': 'Запросы на склад',

  //Links
  Links: 'Ссылки',
  'Links settings': 'Настройки ссылок',

  //Messages
  'Messages': 'Сообщения',

  //Cars
  Car: 'Автомобиль',
  Cars: 'Автомобили',
  'Adding a new car': 'Добавление автомобиля',
  'Car editing': 'Редактирование автомобиля',
  'Owner': 'Владелец',
  'Make': 'Марка',
  'Model': 'Модель',
  'Registration number': 'Регистрационный номер',
  'Plate number': 'Регистрационный номер',
  'VIN number': 'VIN-код',
  'Engine code': 'Код двигателя',
  'Production year': 'Год производства',
  'Year': 'Год',
  'Drivetrain': 'Ведущий мост',
  'Fuel': 'Топливо',
  'Transmission': 'Коробка передач',
  'Power': 'Мощность',
  'kW': 'кВ',
  'hp': 'л.c.',
  'Goods': 'Kaubad',
  'Mileage': 'Пробег',

  //Suppliers
  Supplier: 'Поставщик',
  Suppliers: 'Поставщики',
  'Adding a new supplier': 'Добавление нового поставщика',
  'Supplier editing': 'Редактирование поставщика',
  'Supplier name': 'Название поставщика',
  'Bank name': 'Название банка',
  'Bank account': 'Банковский счет',

  //Taxes
  Taxes: 'Налоги',

  //Cashboxes
  Cashbox: 'Касса',
  Cashboxes: 'Кассы',

  //Sales invoices
  'Sales invoices': 'Счета продаж',
  'Sales invoice': 'Счет продажи',

  //Purchase invoices
  'Purchase invoices': 'Счета покупок',
  'Invoice number already exists': 'Номер счета уже существует',
  Requests: 'Запросы',
  Returns: 'Возвраты',

  //Cashbox transactions
  'Cashbox transactions': 'Кассовые операции',
  'All cashboxes': 'Все кассы',

  //Bank payments
  'Bank payments': 'Банковские платежи',

  //Card payments
  'Card payments': 'Платежи картой',

  //Working areas
  'Working areas': 'Рабочие зоны',
  'Working area': 'Рабочая зона',
  'Adding a new working area': 'Добавление новой рабочей зоны',
  'Working area editing': 'Редактирование рабочей зоны',

  //Services
  Services: 'Услуги',
  'Adding a new service': 'Добавление новой услуги',
  'Service editing': 'Редактирование услуги',
  'Adding a new service category': 'Добавление новой категории услуг',
  'Service category editing': 'Редактирование категории услуги',
  Category: 'Категория',

  //Settings
  Settings: 'Настройки',

  //Email settings
  'Email settings': 'Настройки почты',
  'Email accounts': 'Почтовые аккаунты',
  Emails: 'Электронные письма',

  //App Config Component:
  'Input Style': 'Стиль Ввода',
  Outlined: 'Обрисованный',
  Filled: 'Заполненный',
  'Ripple Effect': 'Волновой эффект',
  'Menu Type': 'Тип меню',
  Static: 'Статическое',
  Overlay: 'Скрытое',
  Horizontal: 'Горизонтальное',
  Slim: 'Тонкое',
  'Color schema': 'Цветовая схема',
  Light: 'Светлая',
  Dark: 'Темная',
  Dim: 'Тусклая',
  Themes: 'Темы',

  //General, common
  Percent: 'Процент',
  'Login': 'Вход в систему',
  'Mo': 'Пн',
  'Tu': 'Вт',
  'We': 'Ср',
  'Th': 'Чт',
  'Fr': 'Пт',
  'Sa': 'Сб',
  'Su': 'Вс',
  Date: 'Дата',
  'Today': 'Сегодня',
  'Yesterday': 'Вчера',
  'Tomorrow': 'Завтра',
  'Current week': 'Текущая неделя',
  'Previous week': 'Предыдущая неделя',
  'Current month': 'Текущий месяц',
  'Previous month': 'Предыдущий месяц',
  'Custom period': 'Произвольный период',
  'Required field': 'Обязательное поле',
  'Value too large': 'Значение слишком велико',
  'Value too small': 'Значение слишком маленькое',
  'Invalid date format': 'Неверный формат даты',
  Name: 'Название',
  Code: 'Код',
  'First name': 'Имя',
  'Last name': 'Фамилия',
  'Full name': 'Полное имя',
  Email: 'Эмэйл',
  Phone: 'Телефон',
  Role: 'Роль',
  Country: 'Страна',
  'Select': 'Выбрать',
  'Please select': 'Пожалуйста выберите',
  Address: 'Адрес',
  City: 'Город',
  State: 'Область/Уезд',
  Postcode: 'Почтовый индекс',
  Language: 'Язык общения',
  'Select language': 'Выберите язык',
  Birthdate: 'Дата рождения',
  Comment: 'Комментарий',
  'Reg. number': 'Рег. номер',
  'VAT number': 'Номер налогообязанного',
  Tax: 'Налог',
  Balance: 'Баланс',
  Profit: 'Прибыль',
  Created: 'Создано',
  Add: 'Добавить',
  'Add category': 'Добавить категорию',
  'All categories': 'Все категории',
  Delete: 'Удалить',
  Search: 'Поиск',
  Page: 'Страница',
  Error: 'Ошибка',
  'Unknown error': 'Неизвестная ошибка',
  Counters: 'Счетчики',
  'Total records': 'Количество записей',
  'User archive': 'Архив клиентов',
  'All Rights Reserved': 'Все права защищены',
  'No data available': 'Данные отсутствуют',
  Edit: 'Редактировать',
  Save: 'Сохранить',
  Cancel: 'Отменить',
  Clear: 'Очистить',
  'Data saved': 'Данные сохранены',
  'Data updated': 'Данные обновлены',
  'Data deleted': 'Данные удалены',
  Successful: 'Успешно',
  Confirm: 'Подтверждение',
  Close: 'Закрыть',
  'Are you sure you want to delete': 'Вы уверены, что хотите удалить',
  'Are you sure you want to delete selected item?': 'Вы уверены, что хотите удалить выбранную позицию?',
  'Are you sure you want to delete selected items?': 'Вы уверены, что хотите удалить выбранные позиции?',
  'Are you sure you want to proceed?': 'Вы уверены, что хотите продолжить?',
  Yes: 'Да',
  No: 'Нет',
  Deleted: 'Удалено',
  Restored: 'Восстановлено',
  h: 'ч',  //Hour
  hours: 'часы',
  km: 'км',
  'Change history': 'История изменений',
  'No results found': 'Результатов не найдено',
  'No available options': 'Нет доступных вариантов',
  'Value is not a valid email address': 'Значение не является адресом электронной почты',
  Undefined: 'Не определено',
  'Phone number has already been taken': 'Номер телефона уже занят',
  'Registration number has already been taken': 'Регистрационный номер уже занят',
  'Username has already been taken': 'Имя пользователя уже занято',
  'This page is under construction': 'Эта страница находится в стадии разработки',
  // Maintenance: 'Обслуживание',
  Maintenance: 'Т.О.',
  Size: 'Размер',
  Continue: 'Продолжить',
  'End session': 'Завершить сеанс',
  'Create new': 'Создать новый',
  Adjustment: 'Округление',
  Debit: 'Дебет',
  Credit: 'Кредит',
}

export default ru
